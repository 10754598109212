<template>
	<div class="box box_mm">
		<div class="box_main">
			<search @search="search" @reset="reset" ref="mysearch" url="/api/proposa/index" :hiddentype="true">
							
			</search>
			<div class="box_cont">
				<div class="btn_list">
					<!-- <el-button type="primary" @click="goaddnew">新增</el-button> -->
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showselect="selecthe"
						:showbm="true" :czwidth="460" @getdata="gettable" @selectdata="selectdata" @lookhb="lookhb">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="look(scope.row)" type="success" size="mini">详情</el-button>
							<el-button @click="edit(scope.row)" v-show="scope.row.type!=3" type="warning"
								size="mini">编辑</el-button>
							<el-button @click="lian(scope.row)" v-show="scope.row.type==1" type="primary" size="mini"
								style="margin-left: 10px;">
								立案
							</el-button>
							<el-button @click="bulian(scope.row)" v-show="scope.row.type==1" type="danger" size="mini"
								style="margin-left: 10px;">
								不立案
							</el-button>
							<el-button @click="zyj(scope.row)" v-show="scope.row.type==1" type="warning" size="mini"
								style="margin-left: 10px;">
								转意见
							</el-button>
							<el-button @click="cxbuyulian(scope.row)" v-show="scope.row.type==3" type="danger"
								size="mini" style="margin-left: 10px;">
								撤销不予立案
							</el-button>
							<el-button @click="showyylist(scope.row)" v-show="scope.row.state_list.length>0"
								type="danger" size="mini" style="margin-left: 10px;">
								查看异议
							</el-button>
							<el-button @click="chexiao(scope.row)" type="danger" size="mini"
								style="margin-left: 10px;">
								撤销
							</el-button>
							<el-button @click="showcbdw(scope.row)"
								v-show="scope.row.type!=1&&scope.row.type!=2&&scope.row.type!=3" type="success"
								size="mini" style="margin-left: 10px;">
								承办单位
							</el-button>

							<el-dropdown @command="handleCommand"
								v-show="(scope.row.type!=1&&scope.row.type!=3)&&(scope.row.zzdta==1||scope.row.zddbta==1)"
								style="margin-left: 10px;">
								<el-button type="primary" size="mini">
									设置提案<i class="el-icon-arrow-down el-icon--right"></i>
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-show="scope.row.zzdta==1"
										:command="{type:'a',id:scope.row.id}">推荐重大提案</el-dropdown-item>
									<el-dropdown-item v-show="scope.row.zddbta==1"
										:command="{type:'b',id:scope.row.id}">推荐重点督办提案</el-dropdown-item>
									<!-- <el-dropdown-item v-show="scope.row.htk==1" :command="{type:'c',id:scope.row.id}">设为回头看项目</el-dropdown-item> -->
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</MyTable>
				</div>
			</div>
		</div>


		<!--详情-->
		<mydetail v-if="showdetail" :id="detail_id" :showdy="true" requsturl="/api/proposa/details"
			@closedetail="showdetail=false">
			<div slot="otherbtn" style="display: flex;flex-direction: column;">
				<el-button @click="lian(rowdata)" v-show="rowdata.type==1" type="primary" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					立案
				</el-button>
				<el-button @click="bulian(rowdata)" v-show="rowdata.type==1" type="danger" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					不立案
				</el-button>
				<el-button @click="zyj(rowdata,1)" v-show="rowdata.type==1" type="warning" size="small"
					style="margin-bottom:10px;margin-left: 10px;">
					转意见
				</el-button>
				<!-- <el-button @click="zyj(rowdata,2)" v-show="rowdata.type==2" type="primary" size="mini"
					style="margin-bottom:10px;margin-left: 10px;">
					交办
				</el-button> -->

			</div>

		</mydetail>

		<!--立案弹出-->
		<el-dialog title="确认立案" :visible.sync="lianshow" width="40%" :before-close="closelian">
			<div>
				<el-form ref="lianform" :model="lianform" :rules="lianrules" label-width="80px">
					<el-form-item label="审查意见"  prop="scyj">
						<el-input  type="textarea" :rows="4"
							placeholder="请输入" v-model="lianform.scyj">
						</el-input>
					</el-form-item>
					<el-form-item label="承办方式" required>
						<el-radio-group v-model="lianform.bltype">
							<el-radio label="1">独立办理</el-radio>
							<el-radio label="2">分办</el-radio>
							<el-radio label="3">主办+协办</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="lianform.bltype!='2'" :label="lianform.bltype=='1'?'办理单位':'主办单位'"
						prop="blusers">
						<el-button size="small" type="primary" @click="selectdw(1)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in lianform.blusers_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="lianform.blusers" filterable key="a11"
							placeholder="请选择">
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item v-if="lianform.bltype=='2'" label="分办单位" prop="bluserslist">
						<el-button size="small" type="primary" @click="selectdw(2)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in lianform.bluserslist_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="lianform.bluserslist" :multiple="true" filterable key="a12"
							placeholder="请选择">
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item v-if="lianform.bltype=='3'" label="协办单位" prop="fbusers">
						<el-button size="small" type="primary" @click="selectdw(3)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in lianform.fbusers_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="lianform.fbusers" multiple filterable placeholder="请选择">
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item label="立案号" prop="lacode">
						<el-input v-model="lianform.lacode" type="text" style="width: 202px;">
						</el-input>
					</el-form-item>
					<el-form-item label="答复时限" prop="dfsx">
						<el-input v-model="lianform.dfsx" type="number" style="width: 202px;">
							<span slot="suffix">天</span>
						</el-input>
					</el-form-item>
					<el-form-item label="提案类型" prop="lei_id">
						<el-select v-model="lianform.lei_id" filterable placeholder="请选择">
							<el-option v-for="item in taleix" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closelian">取 消</el-button>
				<el-button type="primary" @click="qrlian">确 定</el-button>
			</span>
		</el-dialog>


		<!--转意见/交办-->
		<el-dialog :title="this.selecttype==1?'确认转意见':'确认交办'" :visible.sync="yjshow" width="40%"
			:before-close="closeyj">
			<div>
				<el-form ref="yjform" :model="yjform" :rules="yjrules" label-width="80px">
					<el-form-item label="承办方式" required>
						<el-radio-group v-model="yjform.bltype">
							<el-radio label="1">独立办理</el-radio>
							<el-radio label="2">分办</el-radio>
							<el-radio label="3">主办+协办</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="yjform.bltype!='2'" :label="yjform.bltype=='1'?'办理单位':'主办单位'" prop="blusers">
						<el-button size="small" type="primary" @click="selectdw(1)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in yjform.blusers_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="yjform.blusers" filterable placeholder="请选择" key="a132">
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item v-if="yjform.bltype=='2'" label="分办单位" prop="bluserslist">
						<el-button size="small" type="primary" @click="selectdw(2)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in yjform.bluserslist_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="yjform.bluserslist" :multiple="true" filterable placeholder="请选择" key="a142">
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item v-if="yjform.bltype=='3'" label="协办单位" prop="fbusers">
						<el-button size="small" type="primary" @click="selectdw(3)">选择</el-button>
						<div>
							<span>已选人员：<span v-for="item in yjform.fbusers_name">{{item}};</span></span>
						</div>
						<!-- <el-select v-model="yjform.fbusers" multiple filterable placeholder="请选择">							
							<el-option v-for="item in danwei" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select> -->
					</el-form-item>
					<el-form-item v-if="this.selecttype==1" label="转意见后" required>
						<el-radio-group v-model="yjform.yjtype">
							<el-radio label="1">委员来信</el-radio>
							<el-radio label="2">社情民意</el-radio>
							<el-radio label="3">意见建议</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeyj">取 消</el-button>
				<el-button type="primary" @click="qryj">确 定</el-button>
			</span>
		</el-dialog>


		<!--异议列表-->
		<el-dialog title="查看异议" :visible.sync="showyy" width="40%">
			<div style="max-height: 50vh;overflow-y: auto;">
				<div v-for="(item,index) in yylist"
					style="padding: 10px;border-bottom: 1px solid #eee;;margin: 20px 0;">
					<div>处理单位：<span style="font-weight: bold;">{{item.organizational_name}}</span></div>
					<div style="margin-top: 10px;">异议：<span style="color: #d00;">{{item.yyly}}</span></div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="showyy=false">确 定</el-button>
			</span>
		</el-dialog>

		<!--承办单位列表-->
		<cbdwtc ref="mycbdwtc"></cbdwtc>


		<!--选择合并的主案-->
		<el-dialog custom-class="refuse-dialog" title="请选择合并的主案" width="30%" :visible.sync="showhb">
			<div class="myhbbox" style="width:100%;">
				<el-select v-model="selectza" placeholder="请选择" style="width:100%;">
					<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<span slot="footer">
				<el-button @click="showhb=false" size="small">取消</el-button>
				<el-button type="success" @click="hebingta()" size="small">合并</el-button>
			</span>
		</el-dialog>

		<!--查看合并提案-->
		<lookhb ref="mylookhb"></lookhb>



		<!--设为重点督办-->
		<el-dialog title="重点督办" :visible.sync="showdb" :before-close="closedb" width="30%">
			<div style="width: 100%;">
				<div style="margin-bottom: 10px;">请选择督办领导：</div>
				<el-select v-model="zhuanren" filterable remote reserve-keyword placeholder="请输入名字搜索"
					style="width: 100%;" :remote-method="remoteMethod" :loading="loading">
					<el-option v-for="item in userlist" :key="item.id" :label="item.name" :value="item.id">
						<span>{{ item.name }}---</span>
						<span>{{ item.phone }}</span>
					</el-option>
				</el-select>
			</div>

			<span style="width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;">
				<el-button @click="closedb" size="small">取消</el-button>
				<el-button type="success" @click="qrdb()" size="small">确定</el-button>
			</span>
		</el-dialog>

		<selectcbdw ref="myselectcbdw" @selectedry="selecteddw"></selectcbdw>
	</div>
</template>

<script>
	import selectcbdw from '@/components/select_cbdw.vue'
	import mydetail from "@/components/detail_tc.vue";
	import cbdwtc from '../list/component/cbdw_list.vue';
	import lookhb from '../list/component/lookhb.vue';
	import search from '../component/search';
	export default {
		components: {
			mydetail,
			cbdwtc,
			lookhb,
			selectcbdw,
			search
		},
		data() {
			return {
				showdetail: false,
				detail_id: '',
				searchinput: '',
				tableHeader: [{
						name: '提案号',
						prop: 'code',
						width: '80'
					},
					{
						name:'流水号',
						prop:'lscode',
						width:'80'
					},
					{
						name: '标题',
						prop: 'name',
						width: '300'
					},
					{
						name: '提案类别',
						prop: 'lei_name',
						width: ''
					},
					{
						name: '是否为合并提案',
						prop: 'zid',
						width: '130',
						type: 'zid_tbn'
					},
					{
						name: '提交人',
						prop: 'user_name',
						width: ''
					},
					{
						name: '提交时间',
						prop: 'addtime',
						width: ''
					},
					{
						name: '状态',
						prop: 'type',
						width: '120',
						type: 'tags'
					},
					{
						name: '处理异议',
						prop: 'type',
						width: '120',
						type: 'tags_yy'
					}
				],
				tableData: [],
				rowdata: '',
				lianshow: false,
				czid: '', //当前操作的数据id

				lianform: {
					id: '',
					scyj:'',
					bltype: '1',
					blusers: '',
					blusers_name: '',
					bluserslist: '',
					bluserslist_name: '',
					fbusers: '',
					fbusers_name: '',
					dfsx: '',
					lacode:'',
					lei_id: '',
				},
				lianrules: {
					blusers: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					bluserslist: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					fbusers: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					scyj:[{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					dfsx: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					lacode:[{
						required: true,
						message: '请输入',
						trigger: 'change'
					}],
					lei_id: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
				},
				danwei: [],
				taleix: [], //提案类型

				yjshow: false,
				yjform: {
					id: '',
					bltype: '1',
					blusers: '',
					blusers_name: '',
					bluserslist: '',
					bluserslist_name: '',
					fbusers: '',
					fbusers_name: '',
					yjtype: '1'
				},
				yjrules: {
					blusers: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					bluserslist: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					fbusers: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}]
				},
				selecttype: '', //转意见 交办使用
				showyy: false,
				yylist: [],

				selecthe: false, //选择提案合并
				options: [], //已选合并 数据
				selectza: '', //已选合并主案id
				showhb: false,


				//设置督办相关
				showdb: false,
				loading: false,
				zhuanren: '',
				userlist: [],
				db_id: '',

				iii: 0
			}
		},
		mounted() {
			this.$refs.mysearch.searchstatus='1,2,4,5,6,7'
			this.$refs.mysearch.searyjstype='2'
			this.gettable()

			//获取承办单位数据
			this.$post({
				url: '/api/organizational/index',
				params: {
					p: 1,
					size: 9999,
					pid: 3
				}
			}).then((res) => {
				this.danwei = res.list
			})
			//获取提案类型
			this.$post({
				url: '/api/lei/index',
				params: {
					p: 1,
					size: 9999
				}
			}).then((res) => {
				this.taleix = res.list
			})

		},
		activated() {
		    this.gettable(); // 每次进入页面时刷新数据
		},
		methods: {
			selecteddw(e) {
				// iii=1 主办 =2分办 ==3协办
				if (this.iii == 1) {
					if (this.yjshow) {
						this.yjform.blusers = e.map((item) => {
							return item.id
						})
						this.yjform.blusers_name = e.map((item) => {
							return item.name
						})
					} else {
						this.lianform.blusers = e.map((item) => {
							return item.id
						})
						this.lianform.blusers_name = e.map((item) => {
							return item.name
						})
					}
				}
				if (this.iii == 2) {
					if (this.yjshow) {
						this.yjform.bluserslist = e.map((item) => {
							return item.id
						})
						this.yjform.bluserslist_name = e.map((item) => {
							return item.name
						})
					} else {
						this.lianform.bluserslist = e.map((item) => {
							return item.id
						})
						this.lianform.bluserslist_name = e.map((item) => {
							return item.name
						})
					}
				}
				if (this.iii == 3) {
					if (this.yjshow) {
						this.yjform.fbusers = e.map((item) => {
							return item.id
						})
						this.yjform.fbusers_name = e.map((item) => {
							return item.name
						})
					} else {
						this.lianform.fbusers = e.map((item) => {
							return item.id
						})
						this.lianform.fbusers_name = e.map((item) => {
							return item.name
						})
					}
				}
			},
			selectdw(iii) {
				this.iii = iii
				// iii=1 主办 =2分办 ==3协办
				let cxidlist = ''
				let cxnamelist = ''
				if (iii == 1) {
					cxidlist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.blusers)) : JSON.parse(JSON.stringify(
						this.lianform.blusers))
					cxnamelist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.blusers_name)) : JSON.parse(JSON
						.stringify(this.lianform.blusers_name))
					this.$refs.myselectcbdw.isdanxuan = true
				}
				if (iii == 2) {
					cxidlist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.bluserslist)) : JSON.parse(JSON
						.stringify(this.lianform.bluserslist))
					cxnamelist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.bluserslist_name)) : JSON.parse(JSON
						.stringify(this.lianform.bluserslist_name))
					this.$refs.myselectcbdw.isdanxuan = false
				}
				if (iii == 3) {
					cxidlist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.fbusers)) : JSON.parse(JSON.stringify(
						this.lianform.fbusers))
					cxnamelist = this.yjshow ? JSON.parse(JSON.stringify(this.yjform.fbusers_name)) : JSON.parse(JSON
						.stringify(this.lianform.fbusers_name))
					this.$refs.myselectcbdw.isdanxuan = false
				}

				let list = []
				if (cxnamelist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						}
						list.push(obj)
					})
				}
				this.$refs.myselectcbdw.selectuser = list
				this.$refs.myselectcbdw.dialogVisible = true

			},
			edit(row) {
				this.$router.push('/system/edit_tian?id=' + row.id)
			},
			qrdb() {
				this.$post({
					url: '/api/proposa/qtcz',
					params: {
						ids: this.db_id,
						dbuser: this.zhuanren,
						zddbta: 2
					}
				}).then((res) => {
					this.closedb()
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			closedb() {
				this.zhuanren = ''
				this.db_id = ''
				this.showdb = false
			},
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					//获取提案类型
					this.$post({
						url: '/api/user/index',
						params: {
							name: query,
							p: 1,
							size: 9999
						}
					}).then((res) => {
						this.loading = false;
						this.userlist = res.list
					})
				} else {
					this.userlist = [];
				}
			},
			handleCommand(e) {
				console.log(e)
				//e.type== a 推荐重大提案   b推荐重点督办提案   c设为回头看项目
				if (e.type == 'b') {
					this.db_id = e.id
					this.showdb = true
					return
				}
				let obj = {
					ids: e.id
				}
				if (e.type == 'a') {
					obj.zzdta = 2
				}
				if (e.type == 'c') {
					obj.htk = 2
				}
				this.$post({
					url: '/api/proposa/qtcz',
					params: obj
				}).then((res) => {
					this.$message.success('操作成功')
					this.gettable()
				})
			},


			lookhb(item) {
				console.log(item)
				this.$post({
					url: '/api/proposa/hbindex',
					params: {
						id: item.id
					}
				}).then((res) => {
					this.$refs.mylookhb.tableData = res.list
					this.$refs.mylookhb.showyy = true
				})
			},
			//合并提案
			hebingta() {
				if (!this.selectza) {
					this.$message({
						type: "warning",
						message: "请选择合并后的主案",
					})
					return false
				}
				this.$router.push({
					path: '/system/tian_hebing',
					query: {
						id: this.selectza
					}
				});
			},
			showhebing() {
				this.$post({
					url: '/api/proposa/xuanindex',
					params: {

					}
				}).then((res) => {
					if (res.list.length < 2) {
						this.$message({
							type: "warning",
							message: "请至少选择两条数据！",
						})
						return false
					}
					this.options = res.list
					this.showhb = true
				})
			},
			changeshowhb() {
				this.selecthe = true
				this.gettable()
			},
			canceldc() {
				this.selecthe = false
				this.$refs.zjlist.clearselect()
			},
			selectdata(row) { //选择
				if (row.zid) {
					this.$message({
						type: "warning",
						message: "不能选择已合并过的提案",
					})
					this.gettable()
					return false
				}
				if (row.type != 1) {
					this.$message({
						type: "warning",
						message: "只有未审核的提案才可以合并",
					})
					this.gettable()
					return false
				}
				this.$post({
					url: '/api/proposa/xuan',
					params: {
						ids: row.id,
						xuan: row.xuan == 1 ? 2 : 1, //1取消 2选择
					}
				}).then((res) => {
					this.gettable()
				})
			},


			//查看承办单位
			showcbdw(item) {
				item.organizational_list.forEach((item, index) => {
					let cbdw_type = 1 //1未答复、2已答复、3已办理、4、有异议--提案 5、采纳--意见，6、不采纳--意见
					if (item.dfhtype == 1 && item.state == 2) {
						cbdw_type = 4
					} else if (item.dfhtype != 1) {
						cbdw_type = item.dfhtype
					} else if (item.dfhtype == 1 && item.sfcn == 0) {
						cbdw_type = 1
					} else if (item.dfhtype == 1 && item.sfcn == 1) {
						cbdw_type = 5
					} else if (item.dfhtype == 1 && item.sfcn == 2) {
						cbdw_type = 6
					}
					item.cbdw_type = cbdw_type
				})
				this.$refs.mycbdwtc.tableData = item.organizational_list
				this.$refs.mycbdwtc.showyy = true
			},


			//查看异议列表
			showyylist(item) {
				this.yylist = item.state_list
				this.showyy = true
			},

			//===========================转意见/交办
			qryj() { //确认转意见
				this.$refs.yjform.validate((valid) => {
					if (valid) {
						if (this.yjform.fbusers.length > 0) {
							this.yjform.fbusers = this.yjform.fbusers.join(',')
						} else {
							this.yjform.fbusers = ''
						}
						if (this.yjform.bltype == '2') {
							this.yjform.blusers = this.yjform.bluserslist.join(',')
							this.yjform.blusers_name = this.yjform.bluserslist_name.length > 0 ? this.yjform
								.bluserslist_name.join(',') : ""
						} else {
							this.yjform.blusers = this.yjform.blusers.join(',')
							this.yjform.blusers_name = this.yjform.blusers_name.length > 0 ? this.yjform
								.blusers_name.join(',') : ''
						}

						this.yjform.fbusers_name = this.yjform.fbusers_name.length > 0 ? this.yjform.fbusers_name
							.join(',') : ''
						this.yjform.id = this.czid
						this.$post({
							url: this.selecttype == 1 ? '/api/proposa/zwyj' : '/api/proposa/jb',
							params: this.yjform
						}).then((res) => {
							this.$message.success('操作成功')
							this.closeyj()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			zyj(item, ii) {
				if (ii = 2) {
					this.yjform.bltype = item.bltype ? item.bltype.toString() : '1'
					this.yjform.blusers = item.blusers ? item.blusers.split(',') : ''
					this.yjform.bluserslist = []
					if (item.bltype != 2) {
						this.yjform.blusers = this.yjform.blusers[0] ? Number(this.yjform.blusers[0]) : ''
					} else {
						this.yjform.bluserslist = this.yjform.blusers[0] ? this.yjform.blusers.map((item, index) => {
							return Number(item)
						}) : []
					}
					this.yjform.fbusers = item.fbusers ? item.fbusers.split(',') : []
					if (this.yjform.fbusers.length > 0) {
						this.yjform.fbusers = this.yjform.fbusers.map((item, index) => {
							return Number(item)
						})
					}
				}
				this.czid = item.id
				this.selecttype = item.type
				this.yjshow = true
			},
			closeyj() {
				this.yjform = {
					id: '',
					bltype: '1',
					blusers: '',
					blusers_name: '',
					bluserslist: '',
					bluserslist_name: '',
					fbusers: '',
					fbusers_name: '',
					yjtype: '1'
				}
				this.yjshow = false
			},
			//===========================转意见END


			bulian(item) {
				this.$prompt('请输入不予立案的理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/proposa/byla',
						params: {
							id: item.id,
							bylaly: value
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				})
			},
			
			chexiao(item){
				this.$confirm('请确认是否撤销? 撤销后该提案将重置为最开始的待接收状态', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/proposa/chehui',
						params: {
							ids: item.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				});
			},
			
			cxbuyulian(item) {
				this.$confirm('请确认是否撤销不予立案?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/proposa/chehui',
						params: {
							ids: item.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					
				});
			},



			//===========================立案
			closelian() {
				this.lianform = {
					id: '',
					bltype: '1',
					scyj:'',
					blusers: '',
					blusers_name: '',
					bluserslist: '',
					bluserslist_name: '',
					fbusers: '',
					fbusers_name: '',
					dfsx: '',
					lacode:'',
					lei_id: '',
				}
				this.lianshow = false
			},
			qrlian() {
				this.$refs.lianform.validate((valid) => {
					if (valid) {
						if (this.lianform.fbusers.length > 0) {
							this.lianform.fbusers = this.lianform.fbusers.join(',')
						} else {
							this.lianform.fbusers = ''
						}
						if (this.lianform.bltype == '2') {
							this.lianform.blusers = this.lianform.bluserslist.join(',')
							this.lianform.blusers_name = this.lianform.bluserslist_name.length > 0 ? this.lianform
								.bluserslist_name.join(',') : ""
						} else {
							this.lianform.blusers = this.lianform.blusers.join(',')
							this.lianform.blusers_name = this.lianform.blusers_name.length > 0 ? this.lianform
								.blusers_name.join(',') : ''
						}

						this.lianform.fbusers_name = this.lianform.fbusers_name.length > 0 ? this.lianform
							.fbusers_name.join(',') : ''
						this.lianform.id = this.czid
						this.$post({
							url: '/api/proposa/la',
							params: this.lianform
						}).then((res) => {
							this.$message.success('操作成功')
							this.closelian()
							this.gettable()
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			lian(item) { //立案
				this.czid = item.id
				this.lianform.lacode = item.lacode
				if(item.blusers&&!item.fbusers_name){//主办 
					this.lianform.blusers = item.blusers.split(',')
					this.lianform.blusers_name = item.blusers_name.split(',')
					this.lianform.bltype = '1'
				}
				if(!item.blusers&&item.fbusers_name){//分办
					this.lianform.bluserslist = item.fbusers.split(',')
					this.lianform.bluserslist_name = item.fbusers_name.split(',')
					this.lianform.bltype = '2'
				}
				if(item.blusers&&item.fbusers_name){//主办+分办
					this.lianform.blusers = item.blusers.split(',')
					this.lianform.blusers_name = item.blusers_name.split(',')
					this.lianform.fbusers = item.fbusers.split(',')
					this.lianform.fbusers_name = item.fbusers_name.split(',')
					this.lianform.bltype = '3'
				}
				this.lianshow = true
				this.lianform.lei_id = item.lei_id
			},
			//===========================立案END






			look(item) {
				this.rowdata = item
				this.detail_id = item.id
				this.showdetail = true
			},
			goaddnew() {
				this.$router.push('/system/add_zhengji')
			},
			addnew() {
				this.$router.push('/system/swiper_add')
			},
			reset() {
				this.$refs.mysearch.searchinput = ''
				this.$refs.mysearch.searchlei = ''
				this.$refs.mysearch.searchhuiyi = ''
				this.$refs.mysearch.searchhb = ''
				this.$refs.mysearch.searchstatusvalue = ''
				this.$refs.mysearch.searchstatus = '1,2,4,5,6,7'
				this.$refs.mysearch.searyjstype = '2'
				this.$refs.mysearch.searchbldw = ''
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/proposa/index',
					params: {
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						name: this.$refs.mysearch.searchinput,
						lei_id: this.$refs.mysearch.searchlei,
						sshy: this.$refs.mysearch.searchhuiyi,
						hidtype: this.$refs.mysearch.searchhb,
						blusers:this.$refs.mysearch.searchbldw,
						type:'1,2,4,5,6,7',
						jstype:'2'
					}
				}).then((res) => {
					res.list.forEach((item,index)=>{
						if(item.jstype==1){
							item.type = 11 //待接收
						}
						if(item.jstype==3){
							item.type = 12 //退回
						}
					})
					this.tableData = res.list
					this.$refs.zjlist.total = res.count

					//回显打勾
					let selectedlist = []
					res.list.forEach((item, index) => {
						if (item.xuan != 1) {
							selectedlist.push(item)
						}
					})
					this.$refs.zjlist.toggleSelection(selectedlist);
				})
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url: '/admin/banner/delete',
							params: {
								id: row.id
							}
						}).then((res) => {
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			}

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {}

	::v-deep .el-table td.el-table__cell {}

	::v-deep .el-table {
		margin-top: 10px;

	}

	::v-deep .el-table__fixed {}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
</style>